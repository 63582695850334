<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <Spinner :spin="spin"/>
    <!-- Header -->
    <section class="header notice mt-">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span @click="devControl" class="fL nowrap">確認兌換內容</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <!-- 紀錄 -->
      <div class="records flexV width padding mt-4">
        <div class="record p-4 max-w-md mx-auto bg-white rounded-lg w-full">
          <div class="mb-4">
            <div class="fS nowrap gray">折抵券名稱</div>
            <input
              type="text"
              class="fL yl-input"
              placeholder=""
              :value="currentCoupon.title"
              disabled
            />
          </div>

          <div class="hr"></div>

          <div class="mb-4">
            <div>
              <!-- class: error -->
              <div class="fS nowrap gray">營運單位</div>
              <input
                type="text"
                class="fL yl-input"
                placeholder=""
                :value="currentCoupon.company"
                disabled
              />
            </div>
          </div>

          <div class="hr"></div>

          <div class="mb-4">
            <div>
              <!-- class: error -->
              <div class="fS nowrap gray">兌換一次所需雲林幣</div>
              <input
                type="text"
                class="fL yl-input"
                placeholder=""
                :value="currentCoupon.pay"
                disabled
              />
            </div>
          </div>
        </div>

        <div
          class="record p-4 max-w-md mx-auto bg-white rounded-lg mb-5 shadow-md w-full"
        >
          <div class="flex items-center justify-between">
            <div class="text-lg">兌換數量</div>
            <div class="flex items-center ml-4">
              <!-- <button
                @click="decrement"
                class="input-number mr-3"
                style="align-items: end"
              >
                -
              </button> -->
              <span
                class="bg-white text-gray-800 py-1 px-4 border-t border-b"
                >{{ form.quantity }}</span
              >
              <!-- <button
                @click="increment"
                class="input-number ml-3"
              >
                +
              </button> -->
            </div>
          </div>
        </div>

        <div
          class="record p-4 max-w-md mx-auto bg-white shadow-md rounded-lg mb-8 w-full"
        >
          <div class="mb-4">
            <div class="text-lg">兌換結算</div>
            <div class="result-container">
              <div class="flex justify-between">
                <div>持有雲林幣:</div>
                <div>{{ totalCoins }} 枚</div>
              </div>
              <div class="flex justify-between">
                <div>扣除雲林幣:</div>
                <div>{{ totalDeduction }} 枚</div>
              </div>
              <div class="flex justify-between">
                <div>剩餘雲林幣:</div>
                <div :class="submitButtonDisabled? 'text-danger' : ''">{{ remainingCoins }} 枚</div>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons">
          <button class="fM" :disabled="submitButtonDisabled" @click="handleExchangeCoupon">
            {{ buttonText }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';

import Spinner from '@/components/Spinner';
import useCoupon from '@/utils/useCoupon';

export default {
  components: { Spinner },
  data() {
    return {
      spin: false,
      form: {
        quantity: 1
      },
      isDev: false,
      clickCount: 0,
    };
  },
  created() {
    this.$store.dispatch("getUserPoints");
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      userPoints: "getTyUserPoints",
      parkingCoupons: 'coupon/parkingCoupons'
    }),
    totalDeduction() {
      return this.form.quantity * this.currentCoupon.pay;
    },
    totalCoins() {
      return this.userPoints && this.userPoints.pointBalance
        ? this.userPoints.pointBalance
        : 0;
    },
    remainingCoins() {
      return this.totalCoins - this.totalDeduction;
    },
    currentCoupon() {
      return this.getCouponDetail(this.$route.params.id);
    },
    submitButtonDisabled() {
      return this.remainingCoins < 0
    },
    buttonText() {
      return this.submitButtonDisabled ? this.$t('exchange-not-enough') : this.$t('exchange-coupon')
    }
  },
  methods: {
    devControl() {
      if (process.env.VUE_APP_ENV !== "dev") {
        return;
      }

      if (this.clickCount >= 3) {
        return
      }

      this.clickCount++;
      if (this.clickCount === 3) {
        this.isDev = true;
        alert("進入開發者模式");
      }
    },
    getCouponDetail(couponId) {
      return this.parkingCoupons.find(coupon => coupon.id === couponId) || {};
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    async sendPoint() {
      const url = `${process.env.VUE_APP_API_HOST}/points/v1/merchants/yunlin/members/${this.user.userId}/transfer`;
      await axios(
        {
          url,
          method: "POST",
          data: {
            receiverPhoneNumber: '0900000000',
            points: this.currentCoupon.pay,
            batchId: '',
            note: '兌換停車折抵券'
          }
        }
      )
      this.$store.dispatch("getUserPoints")
    },
    async exchangeCoupon() {
      const data = {
        phoneNumber: this.user.phone,
        amount: this.currentCoupon.pay,
        exchangeTime: moment().format('YYYY/MM/DD HH:mm:ss'),
        transactionNumber: uuidv4(),
      };
      const res = await useCoupon().exchange(data)
      return res?.data?.couponNumber
    },
    storeCoupon(couponNumber) {
      const storeData = {
        id: uuidv4(),
        couponId: this.currentCoupon.id,
        couponNumber: couponNumber,
        title: this.currentCoupon.title,
        company: this.currentCoupon.company,
        type: this.currentCoupon.type,
        pay: this.currentCoupon.pay,
        isUsed: false,
        userId: this.user.userId,
      }
      this.$store.dispatch('coupon/addUserCoupon', storeData);
    },
    async handleExchangeCoupon() {
      const handler = async () => {
        try {
          if (!this.isDev) {
            await this.sendPoint();
          }
          const couponNumber = await this.exchangeCoupon()
          if (!couponNumber) {
            return
          }

          this.storeCoupon(couponNumber)
          this.$notify({
            group: 'normal',
            type: 'success',
            title: '兌換成功',
            duration: 3000,
          })
          this.goBack()
        } catch (error) {
          console.error(error);
          this.$notify({
            group: 'normal',
            type: 'error',
            title: '兌換失敗',
            duration: 3000,
          })
        }
      }

      this.spin = true;
      await handler();
      this.spin = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.yl-input {
  width: 100%;
}
.hr {
  border: 1px solid #e8e8e8;
  margin: 16px auto;
}

.record {
  padding: 16px !important;
  margin-bottom: 24px !important;
}

.result-container {
  line-height: 2;
  color: #636363;
  font-size: 1.125rem;
}

button {
  color: white;
  height: 51px !important;
  border-radius: 20px !important;

  &:disabled {
    background: #BDBDBD;
    cursor: not-allowed;
  }
}

.input-number {
  width: 20px;
  height: 20px !important;
}

</style>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
