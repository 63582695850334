<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">兌換停車折抵券</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <div
        class="filter flexH width bg-white justify-between board-pad-2"
        style="width: 97%"
      >
        <div class="tabs line fM rounded">
          <a
            class="tab"
            :class="status === 0 ? 'active' : ''"
            @click="() => toStatus(0)"
          >
            <span class="fM">可兌換</span>
          </a>

          <a
            class="tab"
            :class="status === 1 ? 'active' : ''"
            @click="() => toStatus(1)"
          >
            <span class="fM">已兌換</span>
          </a>

          <a
            class="tab"
            :class="status === 2 ? 'active' : ''"
            @click="() => toStatus(2)"
          >
            <span class="fM">已使用</span>
          </a>
        </div>
      </div>

      <!-- 可兌換 -->
      <div
        v-if="parkingCoupons.length !== 0 && status === 0"
        class="records flexV width padding"
      >
        <div v-for="(item, index) in parkingCoupons" :key="'item' + index">
          <a
            class="record flexH width margin relative"
            @click.stop="detail(item)"
          >
            <div class="img mr-3">
              <img src="@/assets/icon/dev/01.png" />
            </div>
            <div class="flexV width gap-1">
              <span class="fL font-normal cNeutral900 font-lg fz-100"
                >{{ item.title }}
              </span>
              <span class="font-normal cNeutra500">{{ item.company }}</span>
              <span class="fS font-normal cNeutra500 font-sm flex items-center">
                <img class="mr-1" src="@/assets/icon/coin.svg" alt="" />
                {{ `雲林幣 ${item.pay} 枚可兌換` }}
              </span>
            </div>
          </a>
          <div class="lineH"></div>
        </div>
      </div>

      <!-- 已兌換 -->
      <div
        v-else-if="canUsedParkingCoupons.length !== 0 && status === 1"
        class="records flexV width padding"
      >
        <div v-for="(item, index) in canUsedParkingCoupons" :key="'item' + index">
          <a
            class="record flexH width margin relative"
            @click.stop="detail2(item)"
          >
            <div class="img mr-3">
              <img src="@/assets/icon/dev/01.png" />
            </div>
            <div class="flexV width gap-1">
              <span class="fL font-normal cNeutral900 font-lg fz-100"
                >{{ item.title }}
              </span>
              <span class="font-normal cNeutra500">{{ item.company }}</span>
              <span class="fS font-normal cNeutra500 font-sm flex items-center">
                <div>{{ `使用期限：暫無使用期限` }}</div>
              </span>
            </div>
          </a>
          <div class="lineH"></div>
        </div>
      </div>

      <!-- 已使用 -->
      <div
        v-else-if="usedParkingCoupons.length !== 0 && status === 2"
        class="records flexV width padding"
      >
        <div v-for="(item, index) in usedParkingCoupons" :key="'item' + index">
          <a class="record flexH width margin relative">
            <div class="img mr-3">
              <img src="@/assets/icon/dev/01.png" />
            </div>
            <div class="flexV width gap-1">
              <span class="fL font-normal cNeutral900 font-lg fz-100"
                >{{ item.title }}
              </span>
              <span class="font-normal cNeutra500">{{ item.company }}</span>
              <span class="fS font-normal cNeutra500 font-sm flex items-center">
                <!-- <img class="mr-1" src="@/assets/icon/coin.svg" alt="" /> -->
                <div class="text-red-500">{{ `已使用完畢` }}</div>
              </span>
            </div>
          </a>
          <div class="lineH"></div>
        </div>
      </div>

      <div class="empty-text-wrapper text-center flex flex-col gap-6 item-center" v-else>
        <img
          width="160"
          height="160"
          src="@/assets/icon/empty-notification.svg"
        />
        <div class="fL font-medium cNeutral500 text-center">
          {{ emptyText }}
        </div>
      </div>
    </section>
    <ParkingNoticeDialog v-if="showNotice" @close="() => showNotice=false" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment-timezone";

import ParkingNoticeDialog from '@/components/Dialog/ParkingNoticeDialog'
import useCoupon from '@/utils/useCoupon';

export default {
  name: "ParkingCoupons",
  components: {
    ParkingNoticeDialog
  },
  data() {
    return {
      latestReadAt: 0,
      status: 0,
      showNotice: false,
      needCheckNotice: true
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (from.path.includes('parking-coupons')) {
        that.needCheckNotice = false
      }
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    this.checkNoticeDialog()
    this.initQuery()
    this.handleCoupons()
    // console.log("mounted!");
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    // setTimeout(() => {
    //   console.log("Notifications", this.notifications);
    // }, 4000);
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      parkingCoupons: (state) => state.coupon.parkingCoupons,
      userCoupons: (state) => state.coupon.userCoupons,
    }),
    canUsedParkingCoupons() {
      return this.userCoupons.filter((coupon) => !coupon.isUsed);
    },
    usedParkingCoupons() {
      return this.userCoupons.filter((coupon) => coupon.isUsed);
    },
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
    emptyText() {
      const dic = {
        0: "很抱歉，目前尚未有可兌換的折抵券",
        1: "您尚未有折抵券，趕快去看看吧！",
        2: "您尚未有折抵券使用紀錄"
      }
      return dic[this.status]
    }
  },
  methods: {
    async handleCoupons() {
      await this.$store.dispatch('coupon/fetchUserCoupons')
      this.userCoupons.forEach(coupon => {
        if (!coupon.isUsed) {
          this.checkStatus(coupon)
        }
      })
    },
    async checkStatus(coupon) {
      if (!coupon.couponNumber) return
      const res = await useCoupon().checkCouponStatus({ couponNumber: coupon.couponNumber })
      const { isExchanged } = res.data
      if (isExchanged === '1') {
        this.$store.dispatch('coupon/updateUserCoupon', { id: coupon.id, isUsed: true })
      }
    },
    toStatus(value) {
      this.status = value;
      this.$router.replace({ query: { status: value } });
    },
    initQuery() {
      if (this.$route.query.status) {
        this.status = parseInt(this.$route.query.status);
      }
    },
    checkNoticeDialog() {
      if (!this.needCheckNotice) return
      if (localStorage.getItem('notShowParkingNotice') !== '1') {
        this.showNotice = true
      }
    },
    compare(a, b) {
      if (a.ts > b.ts) {
        return -1;
      } else if (a.ts == b.ts) {
        return 0;
      } else if (a.ts < b.ts) {
        return 1;
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    onSnapShot() {
      let collection = db.collection(
        `notifications/yulin/topics/${this.user.user.firestoreChannelId}/messages`
      );
      // doesn't use change to vuefire bind
      collection.onSnapshot((snapshot) => {
        this.notifications = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          this.notifications.push(
            Object.assign({}, data, {
              docId: doc.id,
              unread: data.unread && data.ts > this.latestReadAt,
            })
          );
        });
        this.notifications.sort(this.compare);
        console.log("this.notifications: ", this.notifications);
        this.getLatestReadTime();
      });
    },
    getLatestReadTime() {
      return db
        .collection(
          `notifications/yulin/recipients/${this.user["userId"]}/readTopics`
        )
        .doc(this.user.user.firestoreChannelId)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) return;
          this.latestReadAt = snapshot.data().latestReadAt;
        });
    },
    updateUnreads(_latestReadAt) {
      if (_latestReadAt) this.latestReadAt = _latestReadAt;
      this.notifications.forEach((noti) => {
        if (noti.ts <= this.latestReadAt) noti.unread = false;
      });
    },
    detail(item) {
      console.log(item);
      this.$router.push(`/parking-coupons/confirm/${item.id}`);
    },
    detail2(item) {
      console.log(item);
      this.$router.push(`/parking-coupons/detail/${item.id}`);
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}
.empty-text-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}
</style>